import React, { useState, useEffect } from "react";

const VideoThumbnail: React.FC<{
  thumbnail: string;
  title: string;
  videoId: string;
}> = ({ thumbnail, title, videoId }) => {
  const [showVideo, setShowVideo] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleThumbnailClick = () => {
    setShowVideo(true);
  };

  const handleCloseVideo = () => {
    setShowVideo(false);
  };

  return (
    <div className="relative group">
      {showVideo ? (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4">
          <div className="bg-white p-4 rounded-lg w-full max-w-4xl">
            <div className="relative pb-[56.25%] h-0">
              <iframe
                className="absolute top-0 left-0 w-full h-full"
                src={`https://www.youtube.com/embed/${videoId}`}
                title={title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <button
              className="mt-4 bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 w-full sm:w-auto"
              onClick={handleCloseVideo}
            >
              Close
            </button>
          </div>
        </div>
      ) : (
        <div className="relative cursor-pointer" onClick={handleThumbnailClick}>
          <img
            src={thumbnail}
            alt={title}
            className="w-full h-auto rounded"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-end opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none">
            <p className="text-white p-2 text-sm">{title}</p>
          </div>
        </div>
      )}
    </div>
  );
};

// Grid component to wrap VideoThumbnail components
const VideoGrid: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
    {children}
  </div>
);

export { VideoThumbnail, VideoGrid };