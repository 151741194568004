import React, { useEffect, useRef } from "react";

const AboutPage = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    // ... (existing useEffect code remains the same)
  }, []);

  return (
    <div ref={containerRef} className="flex flex-col items-center justify-center bg-gray-900 text-white py-8 px-4 sm:px-8">
      <div className="text-center mb-8">
        <h1 className="text-3xl sm:text-4xl font-bold mb-2 sm:mb-4">Sylvester P. Lukasiewicz</h1>
        <p className="text-xl sm:text-2xl">
          Founder • <em className="italic">SPL Films, LLC</em>
        </p>
      </div>
      <div className="flex flex-col sm:flex-row items-center justify-center w-full">
        <div className="w-full sm:w-1/2 mb-8 sm:mb-0 sm:mr-8">
          <div className="w-full sm:w-3/4 mx-auto">
            <div className="aspect-w-1 aspect-h-1 rounded-full overflow-hidden">
              <img src="/images/about/sylvester.jpeg" alt="Sylvester P. Lukasiewicz" className="w-full sm:w-auto sm:max-w-xs sm:max-h-200 object-contain mx-auto" />
            </div>
          </div>
        </div>
        <div className="w-full sm:w-1/2">
          <div className="text-base sm:text-lg leading-relaxed text-justify">
            <p>
              Mr. Lukasiewicz moved to New York City from Poland in 1996 to pursue film studies and a career in television. During his studies in corporate and cable communications, he got his start at "<strong>Sesame Street</strong>" and "<strong>CBS Evening News</strong>", while also working on a number of national commercials. Since earning his graduate degree in 2005 Sylvester has been Senior Editor at CUNY TV where his nationally syndicated productions include: "<strong>Black Writers in America</strong>", "<strong>Theater Talk</strong>", "<strong>Black America</strong>" documentaries, "<strong>Talking Pictures</strong>", "<strong>Globe Scholars</strong>" and "<strong>LATiNAS</strong>."
            </p>
            <p className="mt-4">
              The television industry has honored Sylvester's work with eleven NY Emmy Awards nominations including two wins, as well as seventeen Telly Awards and fourteen Communicator Awards recognitions. Sylvester's commercial and branded content portfolio commenced over 20 years ago starting with projects for brands like <strong>Nike</strong>, <strong>Diesel</strong> and <strong>MINI Cooper</strong>. He is a New York-based freelance video journalist for TVN Warner Bros. Discovery's <strong>TVN24</strong>, <strong>TVN24 BiS</strong>, <strong>Fakty TVN</strong> and <strong>TVP Polonia</strong>.
            </p>
            <p className="mt-4">
              With the formation of SPL Films, LLC in 2019, Sylvester continues to produce and co-produce projects like a 4-part biography series, a classical music concert, a cable talk-show, and a corporate video retrospective. A long-time professional member of NY-NATAS, SMPTE and IEEE, Sylvester is also a lifetime member of Golden Key and Phi Beta Kappa honor societies.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { AboutPage };