import { SocialIcon } from 'react-social-icons';
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => (
  <footer className="bg-gray-800 text-white py-4">
    <ul className="flex justify-center">
      <li className="px-8 py-2 hover:bg-gray-700 cursor-pointer">
        <Link to="/about" className="block">ABOUT</Link>
      </li>
      <li className="px-8 py-2 hover:bg-gray-700 cursor-pointer">
        <Link to="/contact" className="block">CONTACT</Link>
      </li>
    </ul>
    <div className="flex justify-center space-x-4 mt-2">
      <SocialIcon url="www.facebook.com" href="http://www.facebook.com/SPLFilmsLLC" style={{ height: 40, width: 40 }} />
      <SocialIcon url="www.instagram.com" href="http://www.instagram.com/splfilmsllc" style={{ height: 40, width: 40 }} />
      <SocialIcon url="www.x.com" href="http://www.x.com/splfilmsllc" style={{ height: 40, width: 40 }} />
    </div>
    <p className="text-center text-sm mt-2">© 2019-2024 SPL Films, LLC. All rights reserved.</p>
  </footer>
);

export { Footer };