import React from "react";
import { Link } from "react-router-dom";

const Navigation = () => (
  <nav className="bg-gray-800 text-white py-2">
    <ul className="flex justify-center">
      <li className="px-8 py-2 hover:bg-gray-700">
        <Link to="/editing" className="block">EDITING</Link>
      </li>
      <li className="px-8 py-2 hover:bg-gray-700">
        <Link to="/videography" className="block">VIDEOGRAPHY</Link>
      </li>
    </ul>
  </nav>
);

export { Navigation };