const videography: Video[] = [
  {
    title: "Cuba | Baseball",
    video: "p0yM-QzhzkM",
    thumbnail: "images/videography/1.jpg"
  },
  {
    title: "Cuba | Calehon De hamel",
    video: "DghQUjfcHtg",
    thumbnail: "images/videography/2.jpg"
  },
  {
    title: "South Korea | Education",
    video: "0O11fVCJr5k",
    thumbnail: "images/videography/3.jpg"
  },
  {
    title: "South Korea | K-Pop",
    video: "kIuJWp87BTw",
    thumbnail: "images/videography/4.jpg"
  },
  {
    title: "South Korea | Meditation",
    video: "iTUZqa9dgKk",
    thumbnail: "images/videography/5.jpg"
  },
  {
    title: "Parada Pułaskiego",
    video: "385W_FjifRU",
    thumbnail: "images/videography/6.jpg"
  },
  {
    title: "Polonia na PiS",
    video: "ddQtErsvaWw",
    thumbnail: "images/videography/7.jpg"
  },
  {
    title: "Pomnik Pozostaje",
    video: "bm4LcH9TLwU",
    thumbnail: "images/videography/8.jpg"
  },
  {
    title: "Pomnik Przeniesiony",
    video: "VC9_OlAg1SQ",
    thumbnail: "images/videography/9.jpg"
  },
  {
    title: "Premier w NYC",
    video: "XBWtZFyc18U",
    thumbnail: "images/videography/10.jpg"
  },
  {
    title: "Protest Polonii",
    video: "2lY5u-8X48Q",
    thumbnail: "images/videography/11.jpg"
  },
  {
    title: "Ruch Bezwizowy",
    video: "km9rrv4a0Wc",
    thumbnail: "images/videography/12.jpg"
  },
  {
    title: "Śmierć Prezydenta",
    video: "ocFLk-qEGAY",
    thumbnail: "images/videography/13.jpg"
  },
  {
    title: "Strajk Kobiet NYC",
    video: "lCmfPkZ3dM8",
    thumbnail: "images/videography/14.jpg"
  },
  {
    title: "Wonderlust Voyages",
    video: "OZbkpzeDHtI",
    thumbnail: "images/videography/15.jpg"
  },
  {
    title: "Pasowanie Marszałka Parady | Grand Marshall Sashing",
    video: "mhsKnDfp2pU",
    thumbnail: "images/videography/15.jpg"
  },
  {
    title: "PM Tusk in DC | Premier Tusk w Waszyngtonie",
    video: "PBWLCLZFmHA",
    thumbnail: "images/videography/16.jpg"
  },
  {
    title: "President Duda in DC | Prezydent Duda w Waszyngtonie",
    video: "CR1veSJXnb4",
    thumbnail: "images/videography/17.jpg"
  },
  {
    title: "President Duda in NYC | Prezydent Duda w NYC",
    video: "bDokagfyqW8",
    thumbnail: "images/videography/18.jpg"
  }
]

export { videography };