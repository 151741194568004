import React, { useEffect, useRef } from "react";

const HomePage = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const calculateAvailableHeight = () => {
      const headerHeight = document.querySelector("header")?.offsetHeight || 0;
      const footerHeight = document.querySelector("footer")?.offsetHeight || 0;
      const navigationHeight = document.querySelector("nav")?.offsetHeight || 0;
      const availableHeight = window.innerHeight - headerHeight - footerHeight - navigationHeight;
      return availableHeight;
    };

    const updateContainerHeight = () => {
      if (containerRef.current) {
        containerRef.current.style.height = `${calculateAvailableHeight()}px`;
      }
    };

    updateContainerHeight();
    window.addEventListener("resize", updateContainerHeight);

    return () => {
      window.removeEventListener("resize", updateContainerHeight);
    };
  }, []);

  return (
    <div ref={containerRef} className="flex items-center justify-center">
      <div className="w-full h-full relative">
        <img
          src="/images/main.jpg"
          alt="SPL Films Featured Project"
          className="absolute top-0 left-0 w-full h-full object-contain object-center"
        />
      </div>
    </div>
  );
};

export { HomePage };