const editing: Video[] = [
  {
    title: "ABNY 50th Anniversary",
    video: "5uu359CTCb4",
    thumbnail: "images/editing/1.jpg"
  },
  {
    title: "Adam Kownacki Boxing",
    video: "LX2riuaaUlQ",
    thumbnail: "images/editing/2.jpg"
  },
  {
    title: "Dual Language | P.S. 34",
    video: "Bnojs3uxDcQ",
    thumbnail: "images/editing/3.jpg"
  },
  {
    title: "Dual Language | Claremont",
    video: "MYGR2_FVbNs",
    thumbnail: "images/editing/4.jpg"
  },
  {
    title: "Dual Language | New York",
    video: "M9oaDt3kxiI",
    thumbnail: "images/editing/5.jpg"
  },
  {
    title: "NY Emmy® Montage | 2013",
    video: "lk-7RBnVcA0",
    thumbnail: "images/editing/6.jpg"
  },
  {
    title: "NY Emmy® Montage | 2014",
    video: "PuCdHBd7Rfk",
    thumbnail: "images/editing/7.jpg"
  },
  {
    title: "NY Emmy® Montage | 2016",
    video: "7ut6DF78X0o",
    thumbnail: "images/editing/8.jpg"
  },
  {
    title: "NY Emmy® Montage | 2017",
    video: "du6MMOFH5i8",
    thumbnail: "images/editing/9.jpg"
  },
  {
    title: "NY Emmy® Montage | 2018",
    video: "bzOHhaGcYis",
    thumbnail: "images/editing/10.jpg"
  },
  {
    title: "NY Emmy® Montage | 2019",
    video: "k5i4wOD17Nw",
    thumbnail: "images/editing/11.jpg"
  },
  {
    title: "Gnawa Music",
    video: "gLs5kASPZkI",
    thumbnail: "images/editing/12.jpg"
  },
  {
    title: "Lessons in Leadership",
    video: "Awk9WVH2tTs",
    thumbnail: "images/editing/13.jpg"
  },
  {
    title: "Polish Heritage Night",
    video: "6NTlV4scXYc",
    thumbnail: "images/editing/14.jpg"
  }
]

export { editing };