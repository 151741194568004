import React from "react";
import { Link } from "react-router-dom";

const Header = () => (
  <header className="bg-white py-4">
    <Link to="/home">
      <h1 className="text-4xl font-bold text-center">
        <span className="text-red-600">SPL|</span>
        <span className="text-gray-700">FILMS</span>
        <span className="text-sm align-bottom">LLC</span>
      </h1>
    </Link>
  </header>
);

export { Header };