import React from 'react';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { HomePage } from './components/HomePage';
import { ProjectGrid } from './components/ProjectGrid';
import { Navigation } from './components/Navigation';
import { editing } from './features/editing/editings';
import { videography } from './features/videography/videography';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AboutPage } from './components/AboutPage';
import { ContactPage } from './components/ContactPage';

const App = () => {
  return (
    <BrowserRouter>
      <div className="flex flex-col min-h-screen bg-gradient-to-b from-gray-700 to-gray-900 bg-fixed">
        <Header />
        <Navigation />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/editing" element={<ProjectGrid videos={editing} />} />
            <Route path="/videography" element={<ProjectGrid videos={videography} />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </BrowserRouter>
  );
};


export { App };