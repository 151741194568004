import React from "react";
import { VideoThumbnail } from "./VideoThumbnail";
import { ArrowDown } from "lucide-react";

interface ProjectGridProps {
  videos: Video[];
}

const ProjectGrid: React.FC<ProjectGridProps> = ({ videos }) => {
  const handleScrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <div className="relative">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
        {videos.map((video, idx) => (
          <VideoThumbnail
            key={idx}
            thumbnail={video.thumbnail}
            title={video.thumbnail}
            videoId={video.video}
          />
        ))}
      </div>
      <button
        className="fixed bottom-4 right-4 bg-blue-500 text-white px-4 py-2 rounded-full shadow-lg md:hidden"
        onClick={handleScrollToBottom}
      >
        <ArrowDown className="w-6 h-6" />
      </button>
    </div>
  );
};

export { ProjectGrid };